import axios from "axios";

// const BASE_URL = "http://localhost:8080/api_umohe_com/rest/v1";
const BASE_URL = "https://api.umohe.com/rest/v1";

axios.interceptors.request.use(
  function (config) {
    config.headers.common["Accept"] = "application/json";
    config.headers.common["Content-Type"] = "application/json";
    if (localStorage.getItem("user.token")) {
      config.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("user.token");
      // console.log(localStorage.getItem("user.token"));
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    if (error.response && error.response.status === 401) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default {
  // baseUrl: BASE_URL,
  entry: {
    login(data) {
      return axios.post(`${BASE_URL}/accounts/entry`, data);
    },
    forgot(data) {
      return axios.post(`${BASE_URL}/accounts/entry/forgot`, data);
    },
    changePassword(data) {
      return axios.post(`${BASE_URL}/accounts/entry/change_password`, data);
    },
    checkUsername(data) {
      return axios.get(`${BASE_URL}/accounts/username/${data}`);
    },
  },
  accounts: {
    changePassword(data) {
      return axios.put(`${BASE_URL}/accounts/changepass`, data);
    },
    find() {
      return axios.get(`${BASE_URL}/accounts`);
    },
    findMe(id) {
      return axios.get(`${BASE_URL}/accounts/${id}`);
    },
    update(data) {
      return axios.put(`${BASE_URL}/accounts`, data);
    },
    editprofile(id, data) {
      return axios.put(`${BASE_URL}/accounts/${id}`, data);
    },
    updatePicture(data) {
      return axios.put(`${BASE_URL}/accounts/pix`, data);
    },
    roleType(type) {
      return axios.get(`${BASE_URL}/accounts/roletype/${type}`);
    },
    addAdmin(data) {
      return axios.post(`${BASE_URL}/accounts/admin`, data);
    },
    status(id, data) {
      return axios.put(`${BASE_URL}/accounts/status/${id}`, data);
    },
    passReset(data) {
      return axios.put(`${BASE_URL}/accounts/reset`, data);
    },
    delete(id) {
      return axios.delete(`${BASE_URL}/accounts/subscribers/${id}`);
    },
  },
  cms: {
    pages: {
      create(data) {
        return axios.post(`${BASE_URL}/cms/pages`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/cms/pages`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/cms/pages/${id}`);
      },
      updateMeta(id, data) {
        return axios.put(`${BASE_URL}/cms/pages/${id}/meta`, data);
      },
      updateContent(id, data) {
        return axios.put(`${BASE_URL}/cms/pages/${id}/content`, data);
      },
      addContent(id, data) {
        return axios.put(`${BASE_URL}/cms/pages/${id}/content/add`, data);
      },
      editContent(id, data) {
        return axios.put(`${BASE_URL}/cms/pages/${id}/content/edit`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/cms/pages/${id}`);
      },
    },
  },
  mySubscriber: {
    find(id) {
      return axios.get(`${BASE_URL}/accounts/mysubs/subscriber/${id}`);
    },
    certified(data) {
      return axios.get(`${BASE_URL}/accounts/mysubs/certified/${data}`);
    },
    activate(id, data) {
      return axios.put(`${BASE_URL}/accounts/mysubs/activate/${id}`, data);
    },
  },
  subscribers: {
    create(data) {
      return axios.post(`${BASE_URL}/accounts/subscribers`, data);
    },
    upload(data) {
      return axios.post(`${BASE_URL}/accounts/subscribers/upload`, data);
    },
    patient(data) {
      return axios.post(`${BASE_URL}/accounts/subscribers/patients`, data);
    },
    byType(data) {
      return axios.post(`${BASE_URL}/accounts/subscribers/subtypelist`, data);
    },
    find(id) {
      return axios.get(`${BASE_URL}/accounts/subscribers/${id}`);
    },
    regionalMedics(regionsId, categoryId) {
      return axios.get(
        `${BASE_URL}/subscribers/find/${regionsId}/${categoryId}`
      );
    },
    requestMedics(id) {
      return axios.get(`${BASE_URL}/subscribers/request/medics/${id}`);
    },
    update(data, id) {
      return axios.get(`${BASE_URL}/subscribers/${id}`, data);
    },
    list() {
      return axios.get(`${BASE_URL}/subscribers`);
    },
    slimlist() {
      return axios.get(`${BASE_URL}/subscribers/slim`);
    },
    background: {
      create(data) {
        return axios.post(`${BASE_URL}/subscribers/background`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/subscribers/background`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/subscribers/background/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/subscribers/background/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/subscribers/background/${id}`);
      },
    },
    doctypes: {
      list() {
        return axios.get(`${BASE_URL}/subscribers/doctypes`);
      },
    },
    docs: {
      create(data) {
        return axios.post(`${BASE_URL}/subscribers/docs`, data);
      },
      subscriberlist() {
        return axios.get(`${BASE_URL}/subscribers/docs`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/subscribers/docs/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/subscribers/docs/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/subscribers/docs/${id}`);
      },
    },
    subsidiary: {
      create(data) {
        return axios.post(`${BASE_URL}/subscribers/subsidiary`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/subscribers/subsidiary`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/subscribers/subsidiary/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/subscribers/subsidiary/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/subscribers/subsidiary/${id}`);
      },
      geo(id, data) {
        return axios.put(`${BASE_URL}/subscribers/subsidiary/geo/${id}`, data);
      },
      types: {
        create(data) {
          return axios.post(`${BASE_URL}/subscribers/subsidiary/types`, data);
        },
        list() {
          return axios.get(`${BASE_URL}/subscribers/subsidiary/types`);
        },
        find(id) {
          return axios.get(`${BASE_URL}/subscribers/subsidiary/types/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/subscribers/subsidiary/types/${id}`,
            data
          );
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/subscribers/subsidiary/types/${id}`);
        },
      },
    },
    relations: {
      create(data) {
        return axios.post(`${BASE_URL}/subscribers/relation`, data);
      },
      createJson(data) {
        return axios.post(`${BASE_URL}/subscribers/relation/json`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/subscribers/relation`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/subscribers/relation/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/subscribers/relation/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/subscribers/relation/${id}`);
      },
    },
  },
  sub_background: {
    list(id) {
      return axios.get(
        `${BASE_URL}/accounts/subscribers/background/subscriber/${id}`
      );
    },
  },
  sub_docs: {
    list(id) {
      return axios.get(
        `${BASE_URL}/accounts/subscribers/docs/subscriber/${id}`
      );
    },
  },
  patients: {
    history: {
      create(data) {
        return axios.post(`${BASE_URL}/patient/history`, data);
      },
      subscriberlist() {
        return axios.get(`${BASE_URL}/patient/history`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/patient/history/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/patient/history/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/patient/history/${id}`);
      },
    },
    requests: {
      create(data) {
        return axios.post(`${BASE_URL}/patient/request`, data);
      },
      lists() {
        return axios.get(`${BASE_URL}/patient/request`);
      },
      subscriberList() {
        return axios.get(`${BASE_URL}/patient/request/subscriber`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/patient/request/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/patient/request/${id}`, data);
      },
      subscriberAcepts(id, data) {
        return axios.put(`${BASE_URL}/patient/request/accept/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/patient/request/${id}`);
      },
      medicsAppointment() {
        return axios.get(`${BASE_URL}/patient/request/medic/appointment`);
      },
      responses: {
        labs: {
          lists() {
            return axios.get(`${BASE_URL}/patient/req/lab`);
          },
          size() {
            return axios.get(`${BASE_URL}/patient/req/lab/size`);
          },
        },
        pharma: {
          lists() {
            return axios.get(`${BASE_URL}/patient/req/pharma`);
          },
          size() {
            return axios.get(`${BASE_URL}/patient/req/pharma/size`);
          },
        },
      },
    },
    moreInfo: {
      create(data) {
        return axios.post(`${BASE_URL}/patient/request/more`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/patient/request/more/${id}`);
      },
      findByRequestid(id) {
        return axios.get(`${BASE_URL}/patient/request/more/request/${id}`);
      },
      cheeckMoreInfo(id) {
        return axios.get(
          `${BASE_URL}/patient/request/more/request/${id}/provided`
        );
      },
      list() {
        return axios.get(`${BASE_URL}/patient/request/more`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/patient/request/more/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/patient/request/more/${id}`);
      },
    },
  },
  medics: {
    available: {
      create(data) {
        return axios.post(`${BASE_URL}/subscribers/medics/available`, data);
      },
      medic() {
        return axios.get(`${BASE_URL}/subscribers/medics/available/medic`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/subscribers/medics/available/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/subscribers/medics/available/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/subscribers/medics/available/${id}`);
      },
      scheduleOffer(data) {
        return axios.post(
          `${BASE_URL}/subscribers/medics/available/schedule`,
          data
        );
      },
      calendar(subscriberId) {
        return axios.get(
          `${BASE_URL}/subscribers/medics/available/calendar/${subscriberId}`
        );
      },
      appointment() {
        return axios.get(
          `${BASE_URL}/subscribers/medics/available/appointments`
        );
      },
    },
    treatments: {
      diagnostics: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/diagnostics`, data);
        },
        patientRequestFind(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/diagnostics/by/patient-request/${id}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/diagnostics/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/diagnostics/${id}`,
            data
          );
        },
        remove(id) {
          return axios.delete(
            `${BASE_URL}/medics/treatments/diagnostics/${id}`
          );
        },
      },
      history: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/history`, data);
        },
        list() {
          return axios.get(`${BASE_URL}/medics/treatments/history`);
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/history/${id}`);
        },
        update(id, data) {
          return axios.put(`${BASE_URL}/medics/treatments/history/${id}`, data);
        },
        byPatientRequest(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/history/patientreq/${id}`
          );
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/medics/treatments/history/${id}`);
        },
      },
      systemicEnquiry: {
        create(data) {
          console.log(data);
          return axios.post(`${BASE_URL}/medics/treatments/enquiry`, data);
        },
        createFromJSON(data) {
          console.log(data);
          return axios.post(`${BASE_URL}/medics/treatments/enquiry/json`, data);
        },
        list() {
          return axios.get(`${BASE_URL}/medics/treatments/enquiry`);
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/enquiry/${id}`);
        },
        update(id, data) {
          return axios.put(`${BASE_URL}/medics/treatments/enquiry/${id}`, data);
        },
        byPatientRequest(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/enquiry/patientreq/${id}`
          );
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/medics/treatments/enquiry/${id}`);
        },
        removeAll(id) {
          return axios.delete(
            `${BASE_URL}/medics/treatments/enquiry/all/${id}`
          );
        },
      },
      prescription: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/presrequest`, data);
        },
        upload(data) {
          return axios.post(
            `${BASE_URL}/medics/treatments/presrequest/upload`,
            data
          );
        },
        byPatientRequest(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/presrequest/patientreq/${id}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/presrequest/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/presrequest/${id}`,
            data
          );
        },
        remove(id) {
          return axios.delete(
            `${BASE_URL}/medics/treatments/presrequest/${id}`
          );
        },
        removeAll(id) {
          return axios.delete(
            `${BASE_URL}/medics/treatments/presrequest/patientreq/${id}`
          );
        },
        offer(data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/presrequest/pharma/offer`,
            data
          );
        },
        offerList(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/presrequest/pharma/offer/${id}`
          );
        },
        acceptance(data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/presrequest/pharma/accept`,
            data
          );
        },
        pharamaList() {
          return axios.get(
            `${BASE_URL}/medics/treatments/presrequest/pharma/list`
          );
        },
        pharmaRequest() {
          return axios.get(
            `${BASE_URL}/medics/treatments/presrequest/pharma/requests`
          );
        },
        pharmaOffers: {
          craete(data) {
            return axios.post(
              `${BASE_URL}/medics/treatments/pharmaoffers`,
              data
            );
          },
          accepted(id) {
            return axios.get(
              `${BASE_URL}/medics/treatments/pharmaoffers/${id}`
            );
          },
          remove(id) {
            return axios.delete(
              `${BASE_URL}/medics/treatments/pharmaoffers/${id}`
            );
          },
        },
      },
      labRequest: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/labrequest`, data);
        },
        byPatientRequest(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/labrequest/by/patient_request/${id}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/labrequest/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/labrequest/${id}`,
            data
          );
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/medics/treatments/labrequest/${id}`);
        },
      },
    },
  },
  general: {
    country: {
      create(data) {
        return axios.post(`${BASE_URL}/general/country`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/country`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/country/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/country/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/country/${id}`);
      },
    },
    regions: {
      byCountry(id) {
        return axios.get(`${BASE_URL}/general/regions/country/${id}`);
      },
      create(data) {
        return axios.post(`${BASE_URL}/general/regions`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/regions/${id}`);
      },
      list() {
        return axios.get(`${BASE_URL}/general/regions`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/regions/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/regions/${id}`);
      },
    },
    districts: {
      findbyRegions(id) {
        return axios.get(`${BASE_URL}/general/districts/regions/${id}`);
      },
      create(data) {
        return axios.post(`${BASE_URL}/general/districts`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/districts/${id}`);
      },
      list() {
        return axios.get(`${BASE_URL}/general/districts`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/districts/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/districts/${id}`);
      },
    },
    documentType: {
      create(data) {
        return axios.post(`${BASE_URL}/general/doctypes`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/doctypes/${id}`);
      },
      list() {
        return axios.get(`${BASE_URL}/general/doctypes`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/doctypes/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/doctypes/${id}`);
      },
    },
    infoTypes: {
      create(data) {
        return axios.post(`${BASE_URL}/general/infotypes`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/infotypes/${id}`);
      },
      list() {
        return axios.get(`${BASE_URL}/general/infotypes`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/infotypes/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/infotypes/${id}`);
      },
    },
    meeting: {
      create(data) {
        return axios.post(`${BASE_URL}/general/meetypes`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/meetypes`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/meetypes/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/meetypes/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/meetypes/${id}`);
      },
    },
    subscriberTypes: {
      create(data) {
        return axios.post(`${BASE_URL}/general/types`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/types`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/types/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/types/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/types/${id}`);
      },
      dynamic() {
        return axios.get(`${BASE_URL}/general/types/dynamic`);
      },
    },
    systemicat: {
      create(data) {
        return axios.post(`${BASE_URL}/general/systemics`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/systemics`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/systemics/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/systemics/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/systemics/${id}`);
      },
    },
    systemiCatDetail: {
      create(data) {
        return axios.post(`${BASE_URL}/general/systemics/details`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/systemics/details`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/systemics/details/${id}`);
      },
      bySystemiCategory(id) {
        return axios.get(
          `${BASE_URL}/general/systemics/details/category/${id}`
        );
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/systemics/details/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/systemics/details/${id}`);
      },
    },
    labcategory: {
      create(data) {
        return axios.post(`${BASE_URL}/general/labcategory`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/labcategory`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/labcategory/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/labcategory/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/labcategory/${id}`);
      },
    },
    labcategoryDetail: {
      create(data) {
        return axios.post(`${BASE_URL}/general/labreq_detail`, data);
      },
      list(id) {
        return axios.get(`${BASE_URL}/general/labreq_detail/list/${id}`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/labreq_detail/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/labreq_detail/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/labreq_detail/${id}`);
      },
    },
    labcatDetailUnits: {
      create(data) {
        return axios.post(`${BASE_URL}/general/labcat_detail_units`, data);
      },
      list(id) {
        return axios.get(`${BASE_URL}/general/labcat_detail_units/list/${id}`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/labcat_detail_units/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/labcat_detail_units/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/labcat_detail_units/${id}`);
      },
    },
    roles: {
      list() {
        return axios.get(`${BASE_URL}/general/roles`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/roles/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/roles/${id}`, data);
      },
      adminlist() {
        return axios.get(`${BASE_URL}/general/roles/admin`);
      },
    },
    measureCategory: {
      create(data) {
        return axios.post(`${BASE_URL}/general/measurements/category`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/measurements/category`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/measurements/category/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/general/measurements/category/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/measurements/category/${id}`);
      },
    },
    measurements: {
      create(data) {
        return axios.post(`${BASE_URL}/general/measurements`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/measurements`);
      },
      byMeasurementCategory(id) {
        return axios.get(`${BASE_URL}/general/measurements/by/category/${id}`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/measurements/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/measurements/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/measurements/${id}`);
      },
    },
    salutations: {
      create(data) {
        return axios.post(`${BASE_URL}/general/salutations`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/salutations`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/salutations/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/salutations/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/salutations/${id}`);
      },
    },
    marital: {
      list() {
        return axios.get(`${BASE_URL}/general/marital_status`);
      },
    },
    subsidiarytypes: {
      create(data) {
        return axios.post(`${BASE_URL}/general/subsidiarytypes`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/subsidiarytypes`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/subsidiarytypes/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/subsidiarytypes/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/subsidiarytypes/${id}`);
      },
    },
    genders: {
      list() {
        return axios.get(`${BASE_URL}/general/gender`);
      },
    },
    specialties: {
      create(data) {
        return axios.post(`${BASE_URL}/general/specialty`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/specialty/${id}`);
      },
      list(data) {
        return axios.get(`${BASE_URL}/general/specialty`, data);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/specialty/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/specialty/${id}`);
      },
    },
    specialDetails: {
      create(data) {
        return axios.post(`${BASE_URL}/general/specialdetail`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/specialdetail/${id}`);
      },
      list(id) {
        return axios.get(`${BASE_URL}/general/specialdetail/specialty/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/specialdetail/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/specialdetail/${id}`);
      },
    },
    medicat: {
      create(data) {
        return axios.post(`${BASE_URL}/general/medicat`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/medicat`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/medicat/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/medicat/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/medicat/${id}`);
      },
    },
    payments: {
      create(data) {
        return axios.post(`${BASE_URL}/general/payments`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/payments`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/payments/${id}`);
      },
      update(id, d) {
        return axios.put(`${BASE_URL}/general/payments/${id}`, d);
      },
      delete(id) {
        return axios.delete(`${BASE_URL}/general/payments/${id}`);
      },
    },
    medicRoute: {
      create(data) {
        return axios.post(`${BASE_URL}/general/medicroute`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/medicroute`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/medicroute/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/medicroute/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/medicroute/${id}`);
      },
    },
    medicFreq: {
      create(data) {
        return axios.post(`${BASE_URL}/general/medicfreqency`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/medicfreqency`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/medicfreqency/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/medicfreqency/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/medicfreqency/${id}`);
      },
    },
    mediversion: {
      create(data) {
        return axios.post(`${BASE_URL}/general/medivers`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/medivers`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/medivers/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/medivers/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/medivers/${id}`);
      },
    },
    medicines: {
      create(data) {
        return axios.post(`${BASE_URL}/general/medicines`, data);
      },
      upload(data) {
        return axios.post(`${BASE_URL}/general/medicines/upload`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/general/medicines`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/medicines/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/general/medicines/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/general/medicines/${id}`);
      },
    },
    profession: {
      list() {
        return axios.get(`${BASE_URL}/general/profession`);
      },
      country(id) {
        return axios.get(`${BASE_URL}/general/profession/country/${id}`);
      },
    },
  },
  products: {
    stocks: {
      create(data) {
        return axios.post(`${BASE_URL}/products/stocks`, data);
      },
      find(id) {
        return axios.get(`${BASE_URL}/products/stocks/${id}`);
      },
      subsidiaryList(id) {
        return axios.get(`${BASE_URL}/products/stocks/subsidiary/${id}`);
      },
      subscriberStocks() {
        return axios.get(`${BASE_URL}/products/stocks/subsidiary`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/products/stocks/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/products/stocks/${id}`);
      },
    },
    service: {
      create(data) {
        return axios.post(`${BASE_URL}/products/services`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/products/services`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/products/services/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/products/services/${id}`, data);
      },
      addPicture(id, data) {
        return axios.put(`${BASE_URL}/products/services/picture/${id}`, data);
      },
      remove(id, data) {
        return axios.delete(`${BASE_URL}/products/services/${id}`, data);
      },
      subsidiary: {
        create(data) {
          return axios.post(`${BASE_URL}/products/services/subsidiary`, data);
        },
        allServices() {
          return axios.get(`${BASE_URL}/products/services/subsidiary/list`);
        },
        list(branchId) {
          return axios.get(
            `${BASE_URL}/products/services/subsidiary/list/${branchId}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/products/services/subsidiary/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/products/services/subsidiary/${id}`,
            data
          );
        },
        remove(id, data) {
          return axios.delete(
            `${BASE_URL}/products/services/subsidiary/${id}`,
            data
          );
        },
      },
    },
  },
  access: {
    permits: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/permissions`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/permissions`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/permissions/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/accounts/permissions/${id}`, data);
      },
    },
    roleperm: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/roleperm`, data);
      },
      rolelist(id) {
        return axios.get(`${BASE_URL}/accounts/roleperm/roles/${id}`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/roleperm/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/accounts/roleperm/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/accounts/roleperm/${id}`);
      },
    },
  },
};
